import { differenceInYears, isFuture } from 'date-fns'
import * as yup from 'yup'
import { useInsuranceFieldVisible } from '../../hooks/useInsuranceFieldVisible'
import { localeDateStringToDate } from '../../utils/date'

type YupTestConfig = yup.TestConfig<string | undefined, Record<string, any>>
type YupTestFunction = yup.TestFunction<string | undefined, Record<string, any>>

export const getInsuranceSchema = (
  isNewInsuranceDataSectionVisible: boolean,
  isFieldVisible: ReturnType<typeof useInsuranceFieldVisible>,
  locale: string,
) => {
  const getRequiredTest = (
    fieldName: string,
    testFunction: YupTestFunction
  ): YupTestConfig => {
    const test: YupTestFunction = (value, context) => {
      const provider = context.parent.insuranceProvider
      return provider && isFieldVisible(fieldName, provider)
        ? testFunction.call(context, value, context)
        : true
    }
    return {
      name: 'isInsuranceFieldRequired',
      message: 'core.requiredMessage',
      test,
    }
  }

  const requiredString: YupTestFunction = (value) => !!value

  const isPrimaryHolder: YupTestFunction = (value, context) => {
    const isPrimaryHolder = context.parent.isPrimaryHolder
    return isPrimaryHolder === 'true' || !!value
  }

  const areNewMedicalFieldsRequired = (
    hasMedicalInsurancePlan: boolean,
    isMedicalInsurancePrimaryHolder: string
  ) => {
    return (
      isNewInsuranceDataSectionVisible &&
      !!hasMedicalInsurancePlan &&
      isMedicalInsurancePrimaryHolder === 'false'
    )
  }

  const getNewFieldsRequiredFunction = (defaultRequiredFunction: any) =>
    isNewInsuranceDataSectionVisible ? defaultRequiredFunction : () => true

  const schema = {
    // Vision fields
    isPrimaryHolder: yup
      .string()
      .oneOf(['true', 'false'])
      .test(getRequiredTest('isPrimaryHolder', requiredString)),
    primaryMemberID: yup
      .string()
      .test(getRequiredTest('primaryMemberID', requiredString)),
    primaryMemberSSN: yup
      .string()
      .test(getRequiredTest('primaryMemberSSN', requiredString)),
    relationshipWithHolder: yup
      .string()
      .test(
        getRequiredTest(
          'relationshipWithHolder',
          getNewFieldsRequiredFunction(isPrimaryHolder)
        )
      ),
    primaryHolderFirstName: yup
      .string()
      .test(getRequiredTest('primaryHolderFirstName', isPrimaryHolder)),
    primaryHolderLastName: yup
      .string()
      .test(getRequiredTest('primaryHolderLastName', isPrimaryHolder)),
    primaryHolderGender: yup
      .string()
      .test(
        getRequiredTest(
          'primaryHolderGender',
          getNewFieldsRequiredFunction(isPrimaryHolder)
        )
      ),
    primaryHolderDOB: yup
      .string()
      .test({
        name: 'isCentenarian',
        message: 'basics.dobMoreThan100Years',
        test: (primaryHolderDOB) =>
          primaryHolderDOB
            ? differenceInYears(
                new Date(),
                localeDateStringToDate(primaryHolderDOB, locale)
              ) <= 100
            : true,
      })
      .test({
        name: 'isMartyMcFly',
        message: 'core.yearInFuture',
        test: (primaryHolderDOB) =>
          primaryHolderDOB
            ? !isFuture(localeDateStringToDate(primaryHolderDOB, locale))
            : true,
      })
      .test(getRequiredTest('primaryHolderDOB', isPrimaryHolder)),
    primaryHolderAddress: yup
      .string()
      .test(
        getRequiredTest(
          'primaryHolderAddress',
          getNewFieldsRequiredFunction(isPrimaryHolder)
        )
      ),
    primaryHolderCity: yup
      .string()
      .test(
        getRequiredTest(
          'primaryHolderCity',
          getNewFieldsRequiredFunction(isPrimaryHolder)
        )
      ),
    primaryHolderState: yup
      .string()
      .test(
        getRequiredTest(
          'primaryHolderState',
          getNewFieldsRequiredFunction(isPrimaryHolder)
        )
      ),
    primaryHolderZipCode: yup
      .string()
      .matches(/^\d{5}$/, 'basics.zipCodeRegexMessage')
      .test(
        getRequiredTest(
          'primaryHolderZipCode',
          getNewFieldsRequiredFunction(isPrimaryHolder)
        )
      ),
    primaryHolderSSN: yup
      .string()
      .test(getRequiredTest('primaryHolderSSN', isPrimaryHolder)),
    insuranceProvider: yup.string().when('hasInsurancePlan', {
      is: true,
      then: yup.string().required('core.requiredSelection'),
      otherwise: yup.string().notRequired(),
    }),
    hasInsurancePlan: yup.boolean().notRequired(),
    // Medical Fields
    hasMedicalInsurancePlan: yup.boolean().notRequired(),
    medicalInsuranceProviderMemberId: yup
      .string()
      .when('hasMedicalInsurancePlan', {
        is: true,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceProvider: yup.string().when('hasMedicalInsurancePlan', {
      is: true,
      then: yup.string().required('core.requiredSelection'),
      otherwise: yup.string().notRequired(),
    }),
    isMedicalInsurancePrimaryHolder: yup
      .string()
      .oneOf(['true', 'false'])
      .when('hasMedicalInsurancePlan', {
        is: (hasMedicalInsurancePlan: boolean) =>
          isNewInsuranceDataSectionVisible && !!hasMedicalInsurancePlan,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    relationshipWithMedicalPrimaryHolder: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderFirstName: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderLastName: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderGender: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderDOB: yup
      .string()
      .test({
        name: 'isCentenarian',
        message: 'basics.dobMoreThan100Years',
        test: (medicalInsuranceHolderDOB) =>
          medicalInsuranceHolderDOB
            ? differenceInYears(
                new Date(),
                localeDateStringToDate(medicalInsuranceHolderDOB, locale)
              ) <= 100
            : true,
      })
      .test({
        name: 'isMartyMcFly',
        message: 'core.yearInFuture',
        test: (medicalInsuranceHolderDOB) =>
          medicalInsuranceHolderDOB
            ? !isFuture(
                localeDateStringToDate(medicalInsuranceHolderDOB, locale)
              )
            : true,
      })
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderAddress: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderCity: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderState: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsuranceHolderZipCode: yup
      .string()
      .matches(/^\d{5}$/, 'basics.zipCodeRegexMessage')
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),
    medicalInsurancePrimaryHolderSSN: yup
      .string()
      .when(['hasMedicalInsurancePlan', 'isMedicalInsurancePrimaryHolder'], {
        is: areNewMedicalFieldsRequired,
        then: yup.string().required('core.requiredMessage'),
        otherwise: yup.string().notRequired(),
      }),

    // Last boolean fields
    isAAAMember: yup.string().oneOf(['true', 'false']),
    isAARPMember: yup.string().oneOf(['true', 'false']),
    isHMOMember: yup.string().oneOf(['true', 'false']),
  }

  return yup.object(schema).defined()
}

export type InsuranceType = yup.InferType<ReturnType<typeof getInsuranceSchema>>
