import useIsAnz from '@hooks/useIsAnz'
import useLogoProps from '@hooks/useLogoProps'
import React from 'react'
import styled, { css } from 'styled-components'
import { Layout } from '../Layout'
import { ContinueLaterButton } from './ContinueLaterButton'

const StyledHeader = styled.header<{ isAnz: boolean }>`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  background-color: white;

  ${({ theme }) => theme.breakpoints.media.large} {
    position: ${({isAnz}) => isAnz ? 'sticky' : 'relative'};
  }
`

const Wrapper = styled.div`
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1022px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  
  height: ${({ theme: { headerHeight } }) => headerHeight};
`

const BorderBottomWrapper = styled.div`
  border-bottom: 0.5px solid ${({ theme: { colors } }) => colors.darkShade};
`

// prettier-ignore
const LogoDiv = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 120px;
  height: 15px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      min-width: 180px;
      height: 22px;
    }
  `}
`

// prettier-ignore
const LogoImg = styled.img`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 120px;
  height: 15px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      min-width: 180px;
      height: 22px;
    }
  `}
`

export interface HeaderProps extends React.ComponentPropsWithRef<'header'> {
  hideLogo?: boolean
  showContinueLaterButton?: boolean
  continueLaterDataElementId?: string
  showCompletitionTracker?: boolean
  completitionTracketValue?: number
}

export const Header: React.FC<HeaderProps> & {
  ContinueLaterButton: typeof ContinueLaterButton
} = ({
  children,
  showContinueLaterButton = false,
  continueLaterDataElementId,
  showCompletitionTracker = false,
  completitionTracketValue = 0,
  hideLogo = false,
  ...props
}) => {
    const isAnz = useIsAnz()
    const logoProps = useLogoProps()

    const LogoComponent = isAnz ? LogoImg : LogoDiv

    return (
      <StyledHeader isAnz={isAnz} {...props}>
        <BorderBottomWrapper>
          <Wrapper>
            {!hideLogo && <LogoComponent className="intake__logo" {...logoProps} />}
            {showContinueLaterButton && (<ContinueLaterButton data-element-id={continueLaterDataElementId} />)}
          </Wrapper>
        </BorderBottomWrapper>

        {showCompletitionTracker && (
          <Layout.CompletionTracker value={completitionTracketValue} />
        )}
      </StyledHeader>
    )
  }

Header.ContinueLaterButton = ContinueLaterButton
