import { AnzForm } from '@api'
import { useConfig } from '../Context'
import { useCallback, useEffect } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useUpdateEffect } from 'react-use'
import { Form } from '../api'
import { IntakeFormUpdate } from '../types/steps'
import {
  formStateToFormApi,
  formApiToFormState,
} from '../utils/formStateToFromApi'
import {
  confirmation,
  continueLater,
  getLatestStep,
  IntakeFormStep,
  isFormStep,
  useStep,
} from '../utils/routes'
import { useFormMode } from './useFormMode'

export const getTargetStep = (
  nextStep: IntakeFormStep,
  currentStep: Form['step'],
  isBackOffice: boolean
) => {
  if (isBackOffice && nextStep === '6_CONFIRMATION') {
    return 'COMPLETED'
  }
  if (isFormStep(currentStep)) {
    return getLatestStep(nextStep, currentStep)
  }
  if (currentStep === 'CREATED') {
    return nextStep
  }
  return currentStep
}

export const useFormUpdate = (
  data: Form | undefined,
  reset: UseFormMethods['reset'],
  run: (...args: any[]) => void,
  promise: Promise<Form>,
  isFieldVisible?: (fieldName: string, provider: string | undefined) => boolean,
) => {
  const { nextStep, next } = useStep()
  const { shouldUpdateUserData, shouldUpdateStep } = useFormMode()
  const { i18n } = useTranslation()
  const { isBackOffice } = useConfig()
  const locale = i18n.language
  const onSubmit = useCallback(
    async (formData: IntakeFormUpdate) => {
      if (!shouldUpdateUserData) return
      if (!data) throw new Error('Cannot Update Form Data Without Form Data')
      const newData: Form = {
        ...data,
        ...formStateToFormApi(formData, locale, isFieldVisible),
        step: shouldUpdateStep
          ? getTargetStep(nextStep, data.step, isBackOffice)
          : data.step,
      }
      run(newData)
    },
    [
      data,
      locale,
      nextStep,
      run,
      shouldUpdateStep,
      shouldUpdateUserData,
      isBackOffice,
    ]
  )

  useEffect(() => {
    if (data) {
      reset(formApiToFormState(data, locale))
    }
  }, [data, locale, reset])

  useUpdateEffect(() => {
    promise.then(() => next()).catch(() => {})
  }, [promise, next])

  return onSubmit
}

export const useFormComplete = (
  data: Form | AnzForm | undefined,
  nextStep: Form['step'],
  run: (...args: any[]) => void
) => {
  const history = useHistory()

  const pushForm = useCallback(async () => {
    const isFormCompleted = nextStep === 'COMPLETED'

    const redirectTarget = isFormCompleted ? confirmation() : continueLater()

    if (!data) throw new Error('Cannot Update Form Data Without Form Data')

    const newData: Form | AnzForm = {
      ...data,
      step: nextStep,
    }

    run(newData)
    history.push(redirectTarget)
  }, [data, history, nextStep, run])

  return pushForm
}
