import { AUTH_LOCAL_STORAGE_KEY } from '@root/api/core/request'
import { useCallback, useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import useQuery from './useQuery'

const removeJwtFromUrl = () => {
  const urlObj = new URL(window.location.href)

  urlObj.searchParams.delete('jwt')

  return urlObj.href
}

/**
 * Get the jwt from url and localStorage, if they are equals only remove the searchParam in the url.
 * Otherwise overwrite localStorage with the jwt in the url and (in next render) return it.
 * @returns Jwt string
 */
const useJwt = () => {
  const query = useQuery()

  const urlJwt = query.get('jwt') ?? ''
  const [localStorageJwt, setJWT] = useLocalStorage(
    AUTH_LOCAL_STORAGE_KEY,
    urlJwt,
    { raw: true }
  )

  const shouldSaveUrlJwt = !!urlJwt && urlJwt !== localStorageJwt
  if (shouldSaveUrlJwt) {
    setJWT(urlJwt)
  }

  useEffect(() => {
    const urlWithoutJwt = removeJwtFromUrl()

    window.history.pushState({}, document.title, urlWithoutJwt)
  }, [])

  const removeJwt = useCallback(() => setJWT(''), [setJWT])

  return {
    jwt: shouldSaveUrlJwt ? '' : localStorageJwt,
    removeJwt,
  }
}

export default useJwt
